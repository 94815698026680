
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'product product-rows animate borders four animate-loop-0 brands' }, _createElement('div', { 'className': 'product-border product-border-background text-left relative' }, _createElement('div', { 'className': 'product-thumb-container' }, _createElement('div', { 'className': 'relative' }, _createElement('div', { 'className': 'product-thumb text-center title-align' }, _createElement('div', { 'className': 'relative lazycontainer' }, _createElement('a', {
                    'href': this.url,
                    'title': this.removeHTML(this.title),
                    'className': 'img-align product-thumb-main keyed'
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'loading': 'eager',
                    'className': 'js lazyload img-align square',
                    'data-aspectratio': '1.0',
                    'onError': this.onImageError
                })))), _createElement('p', { 'className': 'comparison-p' }, _createElement('label', {
                    'htmlFor': 'compare-' + this.id,
                    'className': 'visually-hidden'
                }, 'Add to compare'), _createElement('input', {
                    'id': 'compare-' + this.id,
                    'type': 'checkbox',
                    'className': 'comparison',
                    'name': 'comparison',
                    'title': 'Add to compare',
                    'value': this.id
                }), _createElement('button', {
                    'className': 'compare-modal',
                    'title': 'Compare up to 5 products'
                }, 'Compare')))), _createElement('div', { 'className': 'product-details' }, _createElement('div', { 'className': 'product-title' }, _createElement('h4', {}, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'title': this.removeHTML(this.title)
                }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'product-variants purchase-section' }, _createElement('div', { 'className': 'product-price' }, _createElement('h6', {}, this.formatPrice(this.price))), _createElement('span', { 'className': 'purchase' }, _createElement('span', { 'className': 'button-common' }, _createElement('form', {
                    'id': 'add-item-form-' + this.id + '-itemsBlock',
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'go-to-cart-itemsBlock variants clearfix'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0],
                    'aria-hidden': 'true'
                }), _createElement('button', {
                    'type': 'submit',
                    'id': 'add-to-cart-' + this.id + '-itemsBlock',
                    'className': 'btn auto-full-width add-to-cart',
                    'data-atc': 'Add to Cart',
                    'name': 'add'
                }, '\n                Add to Cart\n              '), _createElement('p', { 'className': 'feedback' }, _createElement('span', { 'className': 'icon icon-times' }, _createElement('svg', {
                    'height': '12',
                    'width': '12',
                    'viewBox': '0 0 24 24',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'version': '1.1',
                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                    'xmlnssvgjs': 'http://svgjs.com/svgjs'
                }, _createElement('g', {}, _createElement('path', {
                    'd': 'M 5 5 L 19 19',
                    'fill': 'none',
                    'stroke': '#990000',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'strokeWidth': '3px'
                }), _createElement('path', {
                    'd': 'M 19 5 L 5 19',
                    'fill': 'none',
                    'stroke': '#990000',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'strokeWidth': '3px'
                })))), _createElement('span', { 'className': 'icon icon-check' }, _createElement('svg', {
                    'height': '12',
                    'width': '12',
                    'viewBox': '0 0 24 24',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'version': '1.1'
                }, _createElement('g', {}, _createElement('path', {
                    'd': 'M 19 5 L 9 19 L 5 15',
                    'fill': 'none',
                    'stroke': '#1b6109',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'strokeWidth': '3px'
                })))), _createElement('span', { 'className': 'html' }))), this.wheel_mpn ? _createElement('button', {
                    'className': 'cm_view-on-vehicle-btn cm_button btn outline',
                    'onClick': () => window.Convermax.openVisualizationDialog(this.wheel_mpn),
                    'key': '3267'
                }, '\n              View On Vehicle\n            ') : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []