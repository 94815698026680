
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon-search',
                'height': '22',
                'width': '22',
                'viewBox': '0 0 24 24',
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'xmlns': 'http://www.w3.org/2000/svg',
                'version': '1.1',
                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                'xmlnssvgjs': 'http://svgjs.com/svgjs'
            }, _createElement('g', {}, _createElement('path', {
                'd': 'M 0.75 9.263 C 0.75 15.806 7.833 19.896 13.5 16.624 C 16.13 15.106 17.75 12.3 17.75 9.263 C 17.75 2.72 10.667 -1.37 5 1.902 C 2.37 3.42 0.75 6.226 0.75 9.263 Z',
                'fill': 'none',
                'stroke': 'currentColor',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round',
                'strokeWidth': '1.5px'
            }), _createElement('path', {
                'd': 'M 15.041 15.041 L 17.373 17.373',
                'fill': 'none',
                'stroke': 'currentColor',
                'strokeLinejoin': 'round'
            }), _createElement('path', {
                'd': 'M23.03,20.909a1.5,1.5,0,0,1-2.121,2.121L17.02,19.141a1,1,0,0,1,0-1.414l.707-.707a1,1,0,0,1,1.414,0Z',
                'fill': 'none',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round',
                'paintOrder': 'stroke'
            }), _createElement('path', {
                'd': 'M 23.39 20.562 C 23.857 21.106 24.043 21.91 23.874 22.502 C 23.704 23.094 23.13 23.718 22.435 23.892 C 21.815 24.046 21.037 23.805 20.555 23.384 L 16.666 19.495 C 16.414 19.212 16.227 18.779 16.227 18.434 C 16.227 18.089 16.414 17.656 16.666 17.373 L 17.373 16.666 C 17.656 16.414 18.089 16.227 18.434 16.227 C 18.779 16.227 19.212 16.414 19.495 16.666 L 23.39 20.562 Z',
                'fill': 'currentColor'
            }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]