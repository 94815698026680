import { useRef, type FC } from 'react';
import { useSelector } from 'react-redux';

import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import { useCollapsible } from 'Core/hooks/index.js';
import { searchResponseExtraSelector } from 'Core/selectors/search.js';
import { cloneSafe } from 'Utils/components.ts';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import AutoSyncVisualization, {
  type Props as AutoSyncVisualizationProps,
  type Params as AutoSyncVisualizationParams,
  type AutoSyncConfig,
} from './autoSyncVisualization.tsx';
import isMobile from '../../vendor/isMobile';

interface WheelTireVehicleInfoExtra {
  WheelData?: WheelData;
  TireSizes?: string[];
}

interface WheelData {
  FrontAxle: WheelsAxleInfo;
  RearAxle?: WheelsAxleInfo;
  BoltPattern?: string;
  HubBore?: string;
  Hardware?: string;
  Thread?: string;
  Hex?: string;
  TPMS?: string;
  SeatType?: string;
}
interface WheelsAxleInfo {
  Diameter: string;
  Width: number;
  Offset: number;
}

interface Params {
  collapsed: boolean;
  toggleCollapsed: () => void;
  data: WheelTireVehicleInfoExtra;
  vehicleString: string;
  autoSyncVisualization?: TemplateFunctionInvoker<AutoSyncVisualizationParams>;
}

interface AutoSyncVisualizationOptions {
  apiKey: string;
  config: AutoSyncConfig;
}

interface Props {
  template: TemplateFunction<Params>;
  initCollapsed?: boolean;
  autoSyncVisualization?: AutoSyncVisualizationOptions;
}

const WheelTireVehicleInfo: FC<Props> = ({
  template,
  initCollapsed,
  autoSyncVisualization: autoSyncVisualizationOptions,
}) => {
  const rootRef = useRef<HTMLElement>(null);
  const [collapsed, toggleCollapsed] = useCollapsible(rootRef, null, initCollapsed ?? isMobile);

  const selectedVehicle = useSelector(selectedVehicleSelector);

  const wheelTireVehicleInfo = useSelector(searchResponseExtraSelector)
    ?.WheelTireVehicleInfo as WheelTireVehicleInfoExtra;

  if (!wheelTireVehicleInfo) {
    return null;
  }

  const autoSyncVisualization = (templ: TemplateFunction<AutoSyncVisualizationParams>) => {
    if (!autoSyncVisualizationOptions) {
      return null;
    }

    const props = {
      template: templ,
      apiKey: autoSyncVisualizationOptions.apiKey,
      config: {
        height: '150px',
        vehicleImageSize: 640,
        vehicleAngles: [32],
        showColorPicker: false,
        ...autoSyncVisualizationOptions.config,
      } as AutoSyncConfig,
      classModificator: 'vehicle-info',
      key: 'autoSyncVisualization',
    } as AutoSyncVisualizationProps;

    return (<AutoSyncVisualization {...props} />) as TemplateResult;
  };

  const component = template.call({
    collapsed,
    toggleCollapsed,
    data: wheelTireVehicleInfo,
    vehicleString: selectedVehicle.toString(),
    autoSyncVisualization,
  });

  return cloneSafe(component, rootRef);
};

export default WheelTireVehicleInfo;
