
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonAdaptableRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBox(function () {
            return _createElement('div', { 'className': 'cm_searchBox' }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                [this.searchInput(function () {
                        return _createElement('div', { 'className': 'cm_searchInput' }, 'Search...');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })],
                _createElement('span', {
                    'className': 'cm_search-box_clear-container',
                    'key': '812'
                }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '371'
                }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                _createElement('button', {
                    'type': 'button',
                    'title': 'Search',
                    'aria-label': 'search button',
                    'className': 'cm_search-box_submit cm_button cm_button__primary',
                    'data-cm-role': 'add-query',
                    'key': '814'
                }, [_createElement('svg', {
                        'className': 'icon-search',
                        'height': '22',
                        'width': '22',
                        'viewBox': '0 0 24 24',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'version': '1.1',
                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                        'xmlnssvgjs': 'http://svgjs.com/svgjs',
                        'key': '7050'
                    }, _createElement('g', {}, _createElement('path', {
                        'd': 'M 0.75 9.263 C 0.75 15.806 7.833 19.896 13.5 16.624 C 16.13 15.106 17.75 12.3 17.75 9.263 C 17.75 2.72 10.667 -1.37 5 1.902 C 2.37 3.42 0.75 6.226 0.75 9.263 Z',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '1.5px'
                    }), _createElement('path', {
                        'd': 'M 15.041 15.041 L 17.373 17.373',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinejoin': 'round'
                    }), _createElement('path', {
                        'd': 'M23.03,20.909a1.5,1.5,0,0,1-2.121,2.121L17.02,19.141a1,1,0,0,1,0-1.414l.707-.707a1,1,0,0,1,1.414,0Z',
                        'fill': 'none',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'paintOrder': 'stroke'
                    }), _createElement('path', {
                        'd': 'M 23.39 20.562 C 23.857 21.106 24.043 21.91 23.874 22.502 C 23.704 23.094 23.13 23.718 22.435 23.892 C 21.815 24.046 21.037 23.805 20.555 23.384 L 16.666 19.495 C 16.414 19.212 16.227 18.779 16.227 18.434 C 16.227 18.089 16.414 17.656 16.666 17.373 L 17.373 16.666 C 17.656 16.414 18.089 16.227 18.434 16.227 C 18.779 16.227 19.212 16.414 19.495 16.666 L 23.39 20.562 Z',
                        'fill': 'currentColor'
                    })))])
            ]), [this.dropdown(function () {
                    function repeatSections1(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function repeatI2_product(i, iIndex) {
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.image),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From: ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '), [this.review_count ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_review-stars',
                                                        'key': '4680'
                                                    },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2_product.bind(this)),
                                                    _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                                                ]) : null])) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                                'className': 'image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': this.imageUrl,
                                                'alt': 'Image of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '335'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '94'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ]));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections1.bind(this))
                    ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]] : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown","cm:searchBox"]