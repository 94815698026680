import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'buy-wheels-today';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'Garage').location = {
  selector: '#cm-garage',
  class: 'cm_garage-desktop',
};

shopifyGenericDefaults.Widgets.find((w) => w.name === 'MobileGarage').location = {
  selector: '#cm-mobile-garage',
  class: 'cm_garage-mobile',
};

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  window.theme.Collection(document.getElementById('itemsBlock'));
  window.CompareProducts();
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchResult', 'FacetBarWheels', 'FacetBarTires'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '100px',
        },
      },
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
